import React, { Component } from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

const S = {}
S.Index = styled.div`
   {
  }
`

class Index extends Component {
  render() {
    return (
      <S.Index>
        <SEO title="nyvarn.run" />

        <p>nykvarn.run</p>

        <p>Hösten 2019 woop!</p>
        <p>update 1</p>
        {/* {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <div key={node.fields.slug}>
          <h3
          style={{
          marginBottom: rhythm(1 / 4),
          }}
          >
          <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
          {title}
          </Link>
          </h3>
          <small>{node.frontmatter.date}</small>
          <p
          dangerouslySetInnerHTML={{
          __html: node.frontmatter.description || node.excerpt,
          }}
          />
            </div>
          )
        })} */}
      </S.Index>
    )
  }
}
export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
